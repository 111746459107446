// Training Type api
export const trainingTypeBaseUrl = '/config/master-training-types/'
export const trainingTypeList = trainingTypeBaseUrl + 'list'
export const trainingTypeToggleStatus = trainingTypeBaseUrl + 'toggle-status'
export const trainingTypeStore = trainingTypeBaseUrl + 'store'
export const trainingTypeUpdate = trainingTypeBaseUrl + 'update'
export const trainingTypeDestroy = trainingTypeBaseUrl + 'destroy'
// Expertise api
export const expertiseBaseUrl = '/config/master-expertise/'
export const expertiseList = expertiseBaseUrl + 'list'
export const expertiseToggleStatus = expertiseBaseUrl + 'toggle-status'
export const expertiseStore = expertiseBaseUrl + 'store'
export const expertiseUpdate = expertiseBaseUrl + 'update'
export const expertiseDestroy = expertiseBaseUrl + 'destroy'
// Training Categry api
export const trainingCategoryBaseUrl = '/config/master-training-category/'
export const trainingCategoryList = trainingCategoryBaseUrl + 'list'
export const trainingCategoryToggleStatus = trainingCategoryBaseUrl + 'toggle-status'
export const trainingCategoryStore = trainingCategoryBaseUrl + 'store'
export const trainingCategoryUpdate = trainingCategoryBaseUrl + 'update'
export const trainingCategoryDestroy = trainingCategoryBaseUrl + 'destroy'

// Training Title api
export const trainingTitleList = '/config/training-title/list'
export const trainingTitleStore = '/config/training-title/store'
export const trainingTitleUpdate = '/config/training-title/update'
export const trainingTitleToggleStatus = '/config/training-title/toggle-status'
export const trainingTitleDestroy = '/config/training-title/destroy'

// Room Type api
export const roomTypeList = '/config/room-types/list'
export const roomTypeStore = '/config/room-types/store'
export const roomTypeUpdate = '/config/room-types/update'
export const roomTypeToggleStatus = '/config/room-types/toggle-status'
export const roomTypeDestroy = '/config/room-types/destroy'

// Guest House api
export const guestHouseList = '/config/geust-house/list'
export const guestHouseStore = '/config/geust-house/store'
export const guestHouseUpdate = '/config/geust-house/update'
export const guestHouseToggleStatus = '/config/geust-house/toggle-status'
export const guestHouseDestroy = '/config/geust-house/destroy'

// Room Rent type api
export const roomRentList = '/config/room-rents/list'
export const roomRentStore = '/config/room-rents/store'
export const roomRentUpdate = '/config/room-rents/update'
export const roomRentToggleStatus = '/config/room-rents/toggle-status'
export const roomRentDestroy = '/config/room-rents/destroy'

// Pay Grade Setup api
export const payGradeSetupList = '/config/pay-grade-setup/list'
export const payGradeSetupStore = '/config/pay-grade-setup/store'
export const payGradeSetupUpdate = '/config/pay-grade-setup/update'
export const payGradeSetupToggleStatus = '/config/pay-grade-setup/toggle-status'
export const payGradeSetupDestroy = '/config/pay-grade-setup/destroy'

// Accomodation Setup api
export const accomodationSetupBaseUrl = '/config/master-accomodation-setup/'
export const accomodationSetupList = accomodationSetupBaseUrl + 'list'
export const accomodationSetupToggleStatus = accomodationSetupBaseUrl + 'toggle-status'
export const accomodationSetupStore = accomodationSetupBaseUrl + 'store'
export const accomodationSetupUpdate = accomodationSetupBaseUrl + 'update'
export const accomodationSetupDestroy = accomodationSetupBaseUrl + 'destroy'

// Course Document Setup api
export const courseDocumentSetupBaseUrl = '/config/master-course-document-setup/'
export const courseDocumentSetupList = courseDocumentSetupBaseUrl + 'list'
export const courseDocumentSetupToggleStatus = courseDocumentSetupBaseUrl + 'toggle-status'
export const courseDocumentSetupStore = courseDocumentSetupBaseUrl + 'store'
export const courseDocumentSetupUpdate = courseDocumentSetupBaseUrl + 'update'
export const courseDocumentSetupDestroy = courseDocumentSetupBaseUrl + 'destroy'

// FAQ Setup api
export const faqSetupList = '/config/faq-setup/list'
export const faqSetupStore = '/config/faq-setup/store'
export const faqSetupUpdate = '/config/faq-setup/update'
export const faqSetupToggleStatus = '/config/faq-setup/toggle-status'
export const faqSetupDestroy = '/config/faq-setup/destroy'

// Training Quarter Setup api
export const trainingQuarterSetupList = '/config/training-quarter-setup/list'
export const trainingQuarterSetupStore = '/config/training-quarter-setup/store'
export const trainingQuarterSetupUpdate = '/config/training-quarter-setup/update'
export const trainingQuarterSetupToggleStatus = '/config/training-quarter-setup/toggle-status'
export const trainingQuarterSetupDestroy = '/config/training-quarter-setup/destroy'

// Training Quarter Setup api
export const foodPackageApiList = '/config/food-packages/list'
export const foodPackageApiStore = '/config/food-packages/store'
export const foodPackageApiUpdate = '/config/food-packages/update'
export const foodPackageApiToggleStatus = '/config/food-packages/toggle-status'
export const foodPackageApiDestroy = '/config/food-packages/destroy'

// Trainee Evaluation api
export const traineeEvaluationList = '/config/trainee-evaluation/list'
export const traineeEvaluationStore = '/config/trainee-evaluation/store'
export const traineeEvaluationUpdate = '/config/trainee-evaluation/update'
export const traineeEvaluationToggleStatus = '/config/trainee-evaluation/toggle-status'

// Trainer Evaluation api
export const trainerEvaluationList = '/config/trainer-evaluation/list'
export const trainerEvaluationStore = '/config/trainer-evaluation/store'
export const trainerEvaluationUpdate = '/config/trainer-evaluation/update'
export const trainerEvaluationToggleStatus = '/config/trainer-evaluation/toggle-status'

// Vanue Management api
export const vanueManagementList = '/config/master-venue-management/list'
export const vanueManagementStore = '/config/master-venue-management/store'
export const vanueManagementUpdate = '/config/master-venue-management/update'
export const vanueManagementStatus = '/config/master-venue-management/toggle-status'
export const vanueManagementDestroy = '/config/master-venue-management/destroy'

// Vanue Management api
export const reportHeadingList = '/config/report-head/list'
export const reportHeadingStore = '/config/report-head/store'
export const reportHeadingUpdate = '/config/report-head/update'
export const reportToggleStatus = '/config/report-head/toggle-status'
export const reportHeadDetails = '/config/report-head/detail'
export const reportHeadingDestroy = '/config/report-head/destroy'

// Grading Status api
export const gradingStatusList = '/config/grading-status/list'
export const gradingStatusStore = '/config/grading-status/store'
export const gradingStatusUpdate = '/config/grading-status/update'
export const gradingStatusToggleStatus = '/config/grading-status/toggle-status'
export const gradingStatusDestroy = '/config/grading-status/destroy'

// Master Venue Condition api
export const venueConditionList = '/config/venue-condition/list'
export const venueConditionStore = '/config/venue-condition/store'
export const venueConditionUpdate = '/config/venue-condition/update'
export const venueConditionToggleStatus = '/config/venue-condition/toggle-status'
export const venueConditionDestroy = '/config/venue-condition/destroy'

// Master Committee api
export const masterCommitteeList = '/config/committee/list'
export const masterCommitteeStore = '/config/committee/store'
export const masterCommitteeUpdate = '/config/committee/update'
export const masterCommitteeToggleStatus = '/config/committee/toggle-status'
export const masterCommitteeDestroy = '/config/committee/destroy'

// schedule Setup api
export const scheduleSetupList = '/config/schedule-setup/list'
export const scheduleSetupStore = '/config/schedule-setup/store'
export const scheduleSetupUpdate = '/config/schedule-setup/update'
export const scheduleSetupToggleStatus = '/config/schedule-setup/toggle-status'
export const scheduleSetupDestroy = '/config/schedule-setup/destroy'

export const dashboard = 'dashboard'

export const notificationReceivedList = '/notification-received/list'
export const trainingCalendarStoreApi = '/config/training-calendar/store'
export const trainingCalendarListApi = '/config/training-calendar/list'
export const trainingCalendarListByIdApi = '/config/training-calendar/list-by-id'
export const trainingCalendarListByFiscalApi = '/config/training-calendar/list-by-fiscal'
export const trainingCalendarToggleStatus = '/config/training-calendar/change-status'

// Guest Trainer Routes
export const guestTrainerList = '/config/guest-trainer/list'
export const guestTrainerStore = '/config/guest-trainer/store'
export const guestTrainerUpdate = '/config/guest-trainer/update'
export const guestTrainerToggleStatus = '/config/guest-trainer/toggle-status'
export const guestTrainerShowData = '/config/guest-trainer/show'

// Driver Routes
export const driverList = '/config/driver/list'
export const driverStore = '/config/driver/store'
export const driverUpdate = '/config/driver/update'
export const driverToggleStatus = '/config/driver/toggle-status'
export const driverTrainerShowData = '/config/driver/show'

// Duplicate Certificate fee routes
export const duplicateCertificateFeeList = '/config/duplicate-certificate-fee/list'
export const duplicateCertificateFeeStore = '/config/duplicate-certificate-fee/store'
export const duplicateCertificateFeeUpdate = '/config/duplicate-certificate-fee/update'
export const duplicateCertificateFeeToggleStatus = '/config/duplicate-certificate-fee/toggle-status'
export const duplicateCertificateFeeShowData = '/config/duplicate-certificate-fee/show'

// Other trainee type routes
export const otherTraineeTypeList = '/config/other-trainee-type/list'
export const otherTraineeTypeStore = '/config/other-trainee-type/store'
export const otherTraineeTypeUpdate = '/config/other-trainee-type/update'
export const otherTraineeTypeToggleStatus = '/config/other-trainee-type/toggle-status'

// Filter Circular List
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'
export const circularPublicationBatch = circularPublicationBaseUrl + 'batch'

// Personal Info api
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const designationWiseUser = personalInfoBaseUrl + 'officer-list'
